@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600;700;900&display=swap");

body {
  margin: 0;
  transition: all 0.2s ease-in-out;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fefefe;
  height: 100vh;
  /* color: #4d402d; */
  /* overflow-y: hidden; */
}
html,
body {
  margin: auto;
  max-width: 1920px;
  /* background-color: #ccc; */
  /* overflow: hidden; */
}
.smoothTransition {
  transition: all 0.2s ease-in-out;
}

/* sweetalert2 ================================= */
.swal2-styled.swal2-confirm {
  background-color: #4d402d !important;
  font-family: "Kanit" !important;
}
.swal2-styled.swal2-deny {
  background-color: #dc3741 !important;
  font-family: "Kanit" !important;
}
.swal2-styled.swal2-cancel {
  font-family: "Kanit" !important;
}
.swal2-popup {
  font-family: "Kanit" !important;
}
.swal2-container {
  z-index: 10000 !important;
}
.swal2-actions {
  font-family: "Kanit" !important;
}
/* Grid paper */
.grid-paper-white {
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/* Loading =================================*/
.circleLoadingOne {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: #ee6925;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedOne 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedOne {
  10% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.circleLoadingTwo {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedTwo 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedTwo {
  20% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.circleLoadingThree {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: #ee6925;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedThree 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedThree {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.circleLoadingFour {
  transition: all 0.2s ease-in-out;
  width: 50px;
  height: 50px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: circleExpandedFour 1s linear forwards;
  animation-iteration-count: infinite;
}
@keyframes circleExpandedFour {
  70% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
/* grid */
.boxWrapper-container {
  display: grid;
  row-gap: 16px;
}
